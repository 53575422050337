import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Our core disciplines',
  discipline1: 'Consulting and analyzing existing systems & applications.',
  discipline2: 'Developing frontend and middleware applications.',
  discipline3: 'Designing load-stable system environments.',
  discipline4: 'Creating attractive and use-optimized user interfaces.',
  discipline5: 'Creating sustainable user experience concepts.',
  discipline6: 'UI/UX engineering with adaptive prototyping.',
  discipline7: 'Short time to market strategy and execution.',
  discipline8: 'Analyzing business and systems requirements.'
})
