// @flow

import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import messages from '../../../modules/Header/Header.lang'

type Props = {
  ...StyleProps
}

const baseStyles = {}

const PageNav = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <li className={styles.navItem}>
        <ScrollLink to="FairAboutUs" smooth={true} offset={-70}>
          <span className={styles.navLink}>{formatMessage(messages.AboutUs)}</span>
        </ScrollLink>
      </li>
      <li className={styles.navItem}>
        <ScrollLink to="FairProjects" smooth={true} offset={-70}>
          <span className={styles.navLink}>{formatMessage(messages.Projects)}</span>
        </ScrollLink>
      </li>
      <li className={styles.navItem}>
        <ScrollLink to="FairServices" smooth={true} offset={-70}>
          <span className={styles.navLink}>{formatMessage(messages.Services)}</span>
        </ScrollLink>
      </li>
      <li className={styles.navItem}>
        <ScrollLink to="FairProducts" smooth={true} offset={-70}>
          <span className={styles.navLink}>{formatMessage(messages.Products)}</span>
        </ScrollLink>
      </li>
    </>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(PageNav)
