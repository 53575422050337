// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../components/basic/Grid'
import Carousel from '../../../components/basic/Carousel'
import messages from './ProjectsSlider.lang'
import baseStyles from './ProjectsSlider.scss'

/* eslint-disable max-len */
const WGo = <StaticImage src="../../../images/projects/weekend.jpg" alt="" objectFit="cover" className="imageCover" />
const Ald = <StaticImage src="../../../images/projects/aldiana.jpg" alt="" objectFit="cover" className="imageCover" />
const LHH = <StaticImage src="../../../images/projects/LHH.jpg" alt="" objectFit="cover" className="imageCover" />
const Bed = <StaticImage src="../../../images/projects/bedfinder.jpg" alt="" objectFit="cover" className="imageCover" />
/* eslint-enable max-len */

const projects = [
  {
    title: 'weekend.com',
    copyId: 'project1Copy',
    checkListIDs: ['project1Check1', 'project1Check2', 'project1Check3', 'project1Check4'],
    img: WGo
  },
  {
    title: 'aldiana.com',
    copyId: 'project2Copy',
    checkListIDs: ['project2Check1', 'project2Check2', 'project2Check3', 'project2Check4'],
    img: Ald
  },
  {
    title: 'lufthansaholidays.com',
    copyId: 'project3Copy',
    checkListIDs: ['project3Check1', 'project3Check2', 'project3Check3', 'project3Check4'],
    img: LHH
  },
  {
    title: 'bedfinder.com',
    copyId: 'project4Copy',
    checkListIDs: ['project4Check1', 'project4Check2', 'project4Check3', 'project4Check4'],
    img: Bed
  }
]

type Props = {
  ...StyleProps
}

const ProjectsSlider = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Container className={styles.root} fluid>
      <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
      <div className={styles.slider}>
        <Carousel
          styles={styles.slider}
          settings={{
            swipe: true,
            lazyLoad: 'progressive',
            infinite: true,
            dots: true,
            adaptiveHeight: true,
            responsive: [
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1439,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  swipe: true
                }
              },
              {
                breakpoint: 9999,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  swipe: false
                }
              }
            ]
          }}
        >
          {projects.map((item, i) => {
            return (
              <div className={styles.slide} key={'projectSlide' + i}>
                <div className={styles.slideImg}>
                  <div className={styles.slideImgContainer}>{item.img}</div>
                </div>
                <div className={styles.slideContent}>
                  <h3 className={styles.slideTitle}>{item.title}</h3>
                  <div className={styles.slideCopy}>{formatMessage(messages[item.copyId])}</div>
                  <ul className={styles.slideList}>
                    {item.checkListIDs.map((text, j) => {
                      return (
                        <li className={styles.slideListItem} key={'projectCheck' + i + j}>
                          {formatMessage(messages[text])}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ProjectsSlider)
