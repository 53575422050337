import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'See how our products work',
  tsboCheck1: 'Personalized online solution',
  tsboCheck2: 'Modular software solution',
  tsboCheck3: 'Comprehensive tool kit for your business',
  tsboCheck4: 'Full mobile responsiveness',
  tsboCheck5: 'API exports for third parties',
  freshMSCheck1: 'Edit directly in the preview',
  freshMSCheck2: 'Dynamic content from any source',
  freshMSCheck3: 'Multichannel marketing',
  freshMSCheck4: 'Real-time API connectivity',
  freshMSCheck5: 'Suitable for use in white label systems',
  mightyCCheck1: 'Decentralized content sources',
  mightyCCheck2: 'Management of non-bookable files',
  mightyCCheck3: 'Central data management and aggregation',
  mightyCCheck4: 'SEO optimized content delivery and page layout',
  mightyCCheck5: 'Perfect in combination with product data'
})
