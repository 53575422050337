// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import messages from '../../../modules/Header/Header.lang'
import baseStyles from '../../../modules/Header/components/Mainnav/Mainnav.scss'
import Device from '../../basic/Device'
import PageNav from './PageNav'

type Props = {
  ...StyleProps
}

const FairPageNav = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div>
      <div>
        <Device tablet desktop>
          <ul className={styles.navList}>
            <PageNav styles={styles} />
            <li className={styles.navItem}>
              <Link to="/" className={styles.navLink}>
                {formatMessage(messages.companyWebsite)}
              </Link>
            </li>
          </ul>
        </Device>
      </div>
      <div>
        <Device phone>
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <Link to="/" className={styles.navLink}>
                {formatMessage(messages.companyWebsite)}
              </Link>
            </li>
          </ul>
        </Device>
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(FairPageNav)
