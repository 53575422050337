import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  introTitle: 'Rely on over 10 years of travel industry experience',
  introText:
    'Benefit from our holistic approach in meeting your requirements and needs.{br}We see ourselves as systems engineers for innovative and sustainable frontend and middleware systems.{br}We offer you the technological flexibility to respond to business changes and stay one step ahead.{br}Over 10 years of specializing in complex travel frontend solutions.{br}Over 10 years of experience in dealing with a wide range of business requirements.{br}Over 10 years of stumbling, getting up, learning & optimizing.',
  btn: 'Discover our projects'
})
