// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import YouTube from 'react-youtube'
import Button from '../../../components/basic/Button'
import { Container } from '../../../components/basic/Grid'
import productsMessages from '../../Products/Products.lang'
import messages from './ProductsList.lang'
import baseStyles from './ProductsList.scss'

import logoFreshMS from '../../../images/logo/freshms-color.svg'
import logoMightyC from '../../../images/logo/mightyc-color.svg'
import logoTravelsandbox from '../../../images/logo/travelsandbox-color.svg'

type Props = {
  ...StyleProps
}

const data = [
  {
    descriptionId: 'copyTravelsandbox',
    img: logoTravelsandbox,
    checkListIDs: ['tsboCheck1', 'tsboCheck2', 'tsboCheck3', 'tsboCheck4', 'tsboCheck5'],
    btnId: 'aboutTravelsandbox',
    videoId: 'MTVlpi9oCmg',
    link: '/products/travelsandbox/'
  },
  {
    descriptionId: 'copyFreshMS',
    img: logoFreshMS,
    checkListIDs: ['freshMSCheck1', 'freshMSCheck2', 'freshMSCheck3', 'freshMSCheck4', 'freshMSCheck5'],
    btnId: 'aboutFreshMS',
    videoId: 'A_pHmPh2VQM',
    link: '/products/freshms/'
  },
  {
    descriptionId: 'copyMightyC',
    img: logoMightyC,
    checkListIDs: ['mightyCCheck1', 'mightyCCheck2', 'mightyCCheck3', 'mightyCCheck4', 'mightyCCheck5'],
    btnId: 'aboutMightyC',
    videoId: 'IEftJvd981g',
    link: '/products/mightyc/'
  }
]

const ProductsList = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
        <div className={styles.items}>
          {data.map((item, i) => {
            return (
              <div key={'productItem' + i} className={styles.item}>
                <div className={styles.videoContainer}>
                  <YouTube videoId={item.videoId} className={styles.video} containerClassName={styles.videoHolder} />
                </div>
                <div className={styles.content}>
                  <div className={styles.logoHolder}>
                    <img src={item.img} alt="" />
                  </div>
                  <p className={styles.about}>{formatMessage(productsMessages[item.descriptionId])}</p>
                  <ul className={styles.list}>
                    {item.checkListIDs.map((check, j) => {
                      return (
                        <li className={styles.listItem} key={'listItem' + i + j}>
                          {formatMessage(messages[check])}
                        </li>
                      )
                    })}
                  </ul>
                  <Link to={item.link}>
                    <Button styles={styles.btn} tag="span" block color="accent" iconRight="icon-arrow_03-right-medium">
                      {formatMessage(productsMessages[item.btnId])}
                    </Button>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ProductsList)
