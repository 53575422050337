// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import { Container, Row, Col } from '../../../components/basic/Grid'
import messages from './Consulting.lang'
import baseStyles from './Consulting.scss'
import { StaticImage } from 'gatsby-plugin-image'

const image = (
  <StaticImage src="../../../images/pictures/consulting.jpg" alt="" objectFit="cover" className="imageCover" />
)

type Props = {
  ...StyleProps
}

const data = [
  {
    nameId: 'discipline1',
    icon: 'icon-consulting',
    color: 'secondary'
  },
  {
    nameId: 'discipline2',
    icon: 'icon-management',
    color: 'secondary'
  },
  {
    nameId: 'discipline3',
    icon: 'icon-development',
    color: 'secondary'
  },
  {
    nameId: 'discipline4',
    icon: 'icon-design2',
    color: 'tertiary'
  },
  {
    nameId: 'discipline5',
    icon: 'icon-development2',
    color: 'tertiary'
  },
  {
    nameId: 'discipline6',
    icon: 'icon-checklist',
    color: 'tertiary'
  },
  {
    nameId: 'discipline7',
    icon: 'icon-strategy',
    color: 'accent'
  },
  {
    nameId: 'discipline8',
    icon: 'icon-system-integration',
    color: 'accent'
  }
]

const Consulting = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <div className={styles.imgContainer}>{image}</div>
        <div className={styles.textWrap}>
          <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
          <Row>
            {data.map((item, i) => {
              return (
                <Col className={styles.listItem} key={'discipline' + i} md="6">
                  <span className={classNames(styles.listIcon, styles[item.color], item.icon)} />
                  <span className={styles.listName}>{formatMessage(messages[item.nameId])}</span>
                </Col>
              )
            })}
          </Row>
        </div>
      </Container>
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Consulting)
