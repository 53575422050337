// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Device from '../basic/Device'
import ScrollAnimation from 'react-animate-on-scroll'
import PageNav from './FairPageNav/PageNav'
import HeroTeaser from '../../components/HeroTeaser'
import IntroTeaser from '../IntroTeaser'
import CardsScroller from './CardsScroller'
import ProjectsSlider from './ProjectsSlider'
import Consulting from './Consulting'
import ProductsList from './ProductsList'
import Facts from '../AboutPage/Facts'
import ClientsFair from './ClientsFair'
import messages from './FairPage.lang'
import baseStyles from './FairPage.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'

const IntroImage = (
  <StaticImage src="../../images/pictures/expert_image.jpg" objectFit="cover" alt="" className="image" />
)

type Props = {
  ...StyleProps
}

const FairPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <HeroTeaser
        preHeadlines={
          <CardsScroller
            after={
              <Device phone>
                <ul className={styles.nav}>
                  <PageNav styles={styles} />
                </ul>
              </Device>
            }
          />
        }
        responsiveHeight
      />
      <div id="FairAboutUs">
        <IntroTeaser
          title={formatMessage(messages.introTitle)}
          copy={<FormattedMessageSanitized {...messages.introText} />}
          image={IntroImage}
          btn={formatMessage(messages.btn)}
          fullWidth
          btnLink="/references/"
          styles={styles.introTeaser}
        />
      </div>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Facts hideTitle />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <div id="FairProjects">
          <ProjectsSlider />
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <ClientsFair />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <div id="FairServices">
          <Consulting />
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <div id="FairProducts">
          <ProductsList />
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(FairPage)
