// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import { Container } from '../../../components/basic/Grid'
import ClientLogo from '../../Clients/Components/ClientLogo'
import Button from '../../basic/Button'

type Props = {
  ...StyleProps
}

/* eslint-disable max-len */
const logo94 = (
  <StaticImage src="../../../images/clientlogos/act-alltours.png" alt="" objectFit="cover" className="image" />
)
const logo99 = <StaticImage src="../../../images/clientlogos/act-g-j.png" alt="" objectFit="cover" className="image" />
const logo59 = (
  <StaticImage src="../../../images/clientlogos/act-lufthansa.png" alt="" objectFit="cover" className="image" />
)
const logo43 = (
  <StaticImage src="../../../images/clientlogos/logo-aldiana.png" alt="" objectFit="cover" className="image" />
)
const logo20 = (
  <StaticImage src="../../../images/clientlogos/act-hotelplan.png" alt="" objectFit="cover" className="image" />
)
/* eslint-enable max-len */

import messages from '../../Clients/Clients.lang'
import baseStyles from '../../Clients/Clients.scss'

const ClientsFair = ({ styles }: Props) => {
  const { formatMessage } = useIntl()
  const ButtonComponent = () => (
    // eslint-disable-next-line react/prop-types
    <Button styles={styles.btn} tag="div" icon="icon-arrow_03-right-medium">
      {formatMessage(messages.btnShowMore)}
    </Button>
  )

  return (
    <Container className={styles.root} fluid>
      <div className={styles.logoList}>
        <ClientLogo className={styles.logo} logo={logo94} linkToBase />
        <ClientLogo className={styles.logo} logo={logo99} linkToBase />
        <ClientLogo className={styles.logo} logo={logo59} linkToBase />
        <ClientLogo className={styles.logo} logo={logo43} linkToBase />
        <ClientLogo className={styles.logo} logo={logo20} linkToBase />
        <div className={styles.btnWrap}>
          <Link to="/references/">
            <ButtonComponent />
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ClientsFair)
