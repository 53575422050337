import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  title: 'Projects & Clients',
  project1Copy: 'Explore the world on weekends: weekend.com is a platform designed for anyone who wants to make the most of their weekends. Available online and as an iOS and Android app, the service gives customers the power to browse and book weekend getaways from their local airport(s).',
  project1Check1: 'SinglePage web application',
  project1Check2: 'Awarded the “The German Travel Award 2018” by Gruner & Jahr',
  project1Check3: 'Awarded Apple’s “App of the Day”',
  project1Check4: 'Highly customizable build-your-own and traditional travel packages',
  project2Copy: 'Aldiana Clubs & Resorts offers unique vacation experiences with a familial feeling in extraordinary facilities. The high-quality content combined with our flexible freshMS page editing and dynamic marketing possibilities set this website apart.',
  project2Check1: 'SinglePage web application',
  project2Check2: 'White label architecture',
  project2Check3: 'Highly customizable',
  project2Check4: 'Dynamic teaser control',
  project3Copy: 'Lufthansa vacations equipped with our TravelSandbox® white label technology is the answer to the increasing demands on online travel portals to offer a perfect balance between performance, UX, and flexibility.',
  project3Check1: 'Multi-language, -market & -currency ready',
  project3Check2: 'Modular booking integration with out of the box solutions',
  project3Check3: 'Add white labels within weeks',
  project3Check4: 'Innovative product configuration',
  project4Copy: 'bedfinder was the proof of concept for TravelSandbox® 3.0. With an innovative design and its single-page application approach, bedfinder amazed customers all over the U.S. As the first live website with freshcells’ new framework, bedfinder was the benchmark for upcoming projects.',
  project4Check1: 'Book-on-Google integration',
  project4Check2: 'High performance with Symfony3, ReactJS & Redis',
  project4Check3: 'Advanced map search',
  project4Check4: 'Points of interest integration via social media APIs'
})


