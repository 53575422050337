// @flow

import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../components/basic/Grid'
import messages from './CardsScroller.lang'
import baseStyles from './CardsScroller.scss'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'

// photos
/* eslint-disable max-len */
const Tom = <StaticImage src="../../../images/staff/Tom.jpg" alt="" objectFit="cover" className="imageCover" />
const Alex = <StaticImage src="../../../images/staff/Alex.jpg" alt="" objectFit="cover" className="imageCover" />
const Eva = <StaticImage src="../../../images/staff/Eva.jpg" alt="" objectFit="cover" className="imageCover" />
const Marvin = <StaticImage src="../../../images/staff/Marvin.jpg" alt="" objectFit="cover" className="imageCover" />
const Beril = <StaticImage src="../../../images/staff/Beril.jpg" alt="" objectFit="cover" className="imageCover" />

const Stefan = <StaticImage src="../../../images/staff/Stefan.jpg" alt="" objectFit="cover" className="imageCover" />
const Rebecca = <StaticImage src="../../../images/staff/Rebecca.jpg" alt="" objectFit="cover" className="imageCover" />
const Steward = <StaticImage src="../../../images/staff/Steward.jpg" alt="" objectFit="cover" className="imageCover" />
const Matthias = (
  <StaticImage src="../../../images/staff/Matthias.jpg" alt="" objectFit="cover" className="imageCover" />
)

/*
const Patrick = <StaticImage src='../../../images/staff/Patrick.jpg' alt='' objectFit='cover' className='image' />
*/
/* eslint-enable max-len */

type Props = {
  ...StyleProps,
  after?: any
}

const cardsArray = [
  {
    name: 'Tom Hülser',
    role: 'CEO',
    img: Tom,
    phone: '+49 (0) 211 933 01 10 ',
    tel: '+492119330110',
    email: 'tom.huelser@freshcells.de'
  },
  {
    name: 'Alexander Fieder',
    role: 'Project Management',
    img: Alex,
    phone: '+49 (0) 211 933 01 68 ',
    tel: '+492119330168',
    email: 'alexander.fieder@freshcells.de'
  },
  {
    name: 'Eva Piasetzky',
    role: 'Projectmanager',
    img: Eva,
    phone: '+49 (0) 211 933 01 63 ',
    tel: '+4921193301163',
    email: 'eva.piasetzky@freshcells.de'
  },
  {
    name: 'Marvin Strenger',
    role: 'Technical Lead',
    img: Marvin,
    phone: '+49 (0) 211 933 01 43 ',
    tel: '+492119330143',
    email: 'marvin.strenger@freshcells.de'
  },
  {
    name: 'Beril Cetinkaya',
    role: 'Projectmanager',
    img: Beril,
    phone: '+49 (0) 211 933 01 33 ',
    tel: '+492119330133',
    email: 'beril.cetinkaya@freshcells.de'
  },
  {
    name: 'Matthias Leimbach',
    role: 'Projectmanager',
    img: Matthias,
    phone: '+49 (0) 211 933 01 33 ',
    tel: '+492119330133',
    email: 'matthias.leimbach@freshcells.de'
  },
  {
    name: 'Steward van Treel',
    role: 'Projectmanager',
    img: Steward,
    phone: '+49 (0) 211 933 01 33 ',
    tel: '+492119330133',
    email: 'steward.vantreel@freshcells.de'
  },
  {
    name: 'Rebecca Stein',
    role: 'Projectmanager',
    img: Rebecca,
    phone: '+49 (0) 211 933 01 33 ',
    tel: '+492119330133',
    email: 'rebecca.stein@freshcells.de'
  },
  {
    name: 'Stefan Müller',
    role: 'Projectmanager',
    img: Stefan,
    phone: '+49 (0) 211 933 01 33 ',
    tel: '+492119330133',
    email: 'stefan.mueller@freshcells.de'
  }
  /*
  {
    name: 'Patrick',
    role: 'Teamlead UI / UX',
    img: Patrick,
    phone: '+49 (0) 211 933 01 46 ',
    tel: '+492119330146',
    email: 'patrick.pauluhn@freshcells.de'
  }
  */
]

const CardsScroller = ({ styles, after }: Props) => {
  const [selectedCard, setSelectedCard] = useState(0)

  return (
    <div className={styles.root}>
      <Container fluid>
        <h2 className={styles.title}>
          <FormattedMessageSanitized {...messages.title} />
        </h2>
        <div className={styles.cards}>
          {cardsArray.map((card, i) => {
            return (
              <div
                className={classNames(styles.card, i === selectedCard && styles['is-active'])}
                onClick={() => setSelectedCard(i)}
                key={'card' + i}
              >
                <div className={styles.cardImg}>
                  <span className={classNames(styles.cardOverlay, 'icon-arrow_03-right-medium')} />
                  {card.img}
                </div>
                <div className={styles.cardContentWrapper}>
                  <div className={styles.cardContent}>
                    <div className={styles.cardName}>{card.name}</div>
                    <div className={styles.cardRole}>{card.role}</div>
                    <a href={'tel:' + card.tel} target="_blank" rel="noopener noreferrer" className={styles.cardPhone}>
                      <span className={classNames(styles.cardIcon, 'icon-phone')} />
                      {card.phone}
                    </a>
                    <a
                      href={'mailto:' + card.email}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.cardEmail}
                    >
                      <span className={classNames(styles.cardIcon, 'icon-email')} />
                      {card.email}
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {after}
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CardsScroller)
